<template>
  <div class="anggota">
    <h1 class="mb-5">Tambah Anggota</h1>
    <Form :initial-anggota="anggota" :isEdit="false" />
  </div>
</template>

<script>
import Form from "./components/form/Form.vue";
export default {
  components: {
    Form,
  },
  data() {
    return {
      anggota: {
        nama_lengkap: "",
        nama_panggilan: "",
        jenis_kelamin: "",
        tanggal_lahir: "",
        email: "",
        no_hp: "",
        alamat: "",
        propinsi_id: "",
        kota_id: "",
        kecamatan_id: "",
        kelurahan_id: "",
        agama: "",
        status: "",
        kategori_pekerjaan_id: 0,
        pekerjaan_id: 0,
        kategori_hobi_id: 0,
        hobi_id: 0,
        jenis_atribut: "",
        ukuran_atribut: "",
        jenis_identitas: "",
        no_identitas: "",
        foto_identitas: "",
        foto_memegang_identitas: "",
        foto_diri: "",
        ahli_waris_1_nama: null,
        ahli_waris_1_nik: null,
        ahli_waris_1_no_hp: null,
        ahli_waris_2_nik: null,
        ahli_waris_2_nama: null,
        ahli_waris_2_no_hp: null,
        status_anggota: "",
        approved_at: null,
        approver_id: null,
        nomor_kta: "",
        bukti_pembayaran: "",
        qr_code: "",
        notes: "",
        user_id: null,
        approver: null,
      },
    };
  },
};
</script>

<style scoped>
.anggota {
  padding: 3rem 1.5rem;
}
</style>
